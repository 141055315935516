@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: 'Jost', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root {
    height: 100%;
    overflow: hidden;
    overflow-x: auto;
}

.customer-background {
    background-color: linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%);
  }
  

a {
    color: white;
    font-size: 14px;
    font-family: 'Jost';
    text-decoration: none;
}

.invest-dollar {
    transform: scale(1.6);
    position: relative;
    right: 4px;
}
